<template>
  <div class="about-page container-1440" :class="theme">
    <div class="bg" :style="'background-image:url(' + imageUrl + ')'" />

    <h1 class="with-side-padding" v-html="title" />

    <div class="banner-slider">
      <ContentSlider
        v-if="content.banners.length > 0 && isMounted"
        type="legacy"
        :theme-forced="theme"
        :count-slides-change-step="1"
      >
        <Banner
          v-for="(banner, i) in content.banners"
          :key="banner.bannerName + i"
          :component-name="banner.bannerName"
        />
      </ContentSlider>
    </div>

    <div class="links-wrapper row flex with-side-padding">
      <div
        v-for="folder in content.folders"
        :key="folder.folderName"
        class="links-block col col-desktop-4 col-tablet-4 col-mobile-4"
        :class="[`links-block-cols-${folder.pages.length}-width`]"
      >
        <div class="links-title" v-text="folder.folderName" />
        <div class="links">
          <div v-for="list in folder.pages" class="links-list">
            <a
              v-for="item in list"
              :key="item.pageId"
              class="link link-nav-colored"
              :href="item.path"
              v-html="
                item.dataObject.page.meta.titleTrKey
                  ? getTranslation(item.dataObject.page.meta.titleTrKey)
                  : getTranslation(item.dataObject.page.title)
              "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import Global from 'src/mixins/Global';
import CmsComponent from 'src/mixins/CmsComponent';
import ContentSlider from 'src/components/ui/ContentSlider.vue';
import Banner from './Banner.vue';
import { actions } from 'src/store/actions';
import { TCmsPage } from 'src/store/types';
import { TTranslations } from 'src/store/translations/types';
import { TImageCMS } from 'src/store/common/types';

@Component({
  components: { Banner, ContentSlider },
})
export default class AboutPage extends mixins(Global, CmsComponent) {
  isMounted = false;

  content?: {
    title: TTranslations;
    image: TTranslations<TImageCMS>;
    folders: Array<{
      folderName: string;
      pageId: string;
      order: number;
      meta: {
        titleTrKey: string;
      };
      pages: TCmsPage[][];
      columnCount: number;
    }>;
    banners: Array<{
      bannerName: string;
    }>;
  } | null = null;

  get title() {
    return this.getByLanguage(this.content?.title);
  }

  get imageUrl() {
    return this.getByLanguage(this.content?.image)?.url || '';
  }

  get pages() {
    return this.$store.cmsPages;
  }

  async created() {
    if (this.content && this.content.folders) {
      this.content.folders = this.content?.folders
        .sort((a, b) => a.order - b.order)
        .map((folder) => {
          const pagesInFolder = this.pages
            .filter((page) => page.dataObject.page.groupNameKey === folder.folderName)
            .sort((a, b) => a.dataObject.page.order - b.dataObject.page.order);
          const columnCount = folder?.columnCount || 1;
          let pages = [];

          if (columnCount > 1) {
            const countPagesInColumn = Math.ceil(pagesInFolder.length / columnCount);
            for (let i = 0; i < columnCount; i++) {
              pages[i] = pagesInFolder.slice(
                i * countPagesInColumn,
                i * countPagesInColumn + countPagesInColumn
              );
            }
          } else {
            pages = [pagesInFolder];
          }

          return {
            ...folder,
            countPages: pagesInFolder.length,
            pages,
          };
        });
    }

    actions.common.showFooter(this.$store);
    this.isMounted = true;
  }

  destroyed() {
    actions.common.hideFooter(this.$store);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';

.about-page {
  width: 100%;

  .with-side-padding {
    padding-right: 0;
  }

  .bg {
    position: absolute;
    top: -64px;
    right: 0;
    z-index: var(--z-rock-bottom);
    width: 100%;
    height: 1100px;
    background-repeat: no-repeat;
    background-size: contain;

    @include tablet {
      top: -32px;
    }

    @include mobile {
      display: none;
    }
  }

  .banner-slider {
    position: relative;
    width: 100%;
    padding: 0 64px;
    margin-bottom: 64px;

    .content-slider {
      margin: 0;
      overflow: visible;
    }

    &::v-deep {
      .with-side-padding {
        margin: 0;
      }

      .nav-button {
        display: none;
      }

      .inner {
        overflow: visible;
      }

      .nav-button-icon {
        color: var(--c-light-100);
        background-color: var(--c-light-brand);
        border-radius: 50%;

        svg {
          width: 76%;
          height: 76%;
          margin: 12%;
        }
      }
    }

    @include mobile-and-tablet {
      padding: 0 48px;

      &::v-deep {
        .content-slider {
          overflow: hidden;
        }

        .nav-button {
          display: block;
        }
      }
    }

    @include mobile {
      padding: 0 24px;
    }
  }

  h1 {
    max-width: 504px;
    margin-bottom: 96px;

    @include mobile {
      margin-bottom: 48px;
    }
  }

  .links-wrapper {
    margin: 0;

    .links-block {
      flex-direction: row;
      width: 100%;
      margin-right: 0;
      margin-bottom: 48px;
      margin-left: 0;

      @include mobile-s {
        margin-bottom: 24px;
      }
    }

    .links-block-cols-1-width {
      width: 33.333%;

      @include mobile-and-tablet {
        width: 100%;
      }
    }

    .links-block-cols-2-width {
      width: 66.666%;

      @include mobile-and-tablet {
        width: 100%;
      }
    }

    .links-title {
      margin-bottom: 20px;
      @include heading4;
    }

    .links {
      display: flex;
      flex-direction: row;
      width: 100%;

      @include mobile {
        flex-direction: column;
      }

      .links-list {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        align-items: flex-start;
        width: 100%;
        padding-right: 10px;
      }

      .link {
        width: 100%;
        margin-bottom: 12px;
        color: var(--c-light-font-primary);
        border-bottom-color: transparent;

        @include devices-with-hover {
          &:hover,
          &:active,
          &:focus {
            border-bottom-color: transparent;
          }

          &:hover {
            color: var(--c-light-font-primary);
            text-decoration: underline;
          }
        }

        &:active {
          color: var(--c-light-link-hover);
        }

        @include mobile-and-tablet {
          @include devices-with-hover {
            &:hover {
              color: var(--c-dark-font-primary);
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------
  &.dark {
    .bg {
      display: none;
    }

    .links-wrapper {
      .links .link {
        color: var(--c-dark-font-primary);

        @include devices-with-hover {
          &:hover {
            color: var(--c-dark-font-primary);
          }
        }

        &:active {
          color: var(--c-dark-link-hover);
        }
      }
    }
  }
}
</style>
